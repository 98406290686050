<template>
  <div class="LightWeightConfig-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="应用信息">
          <el-form-item label="是否支持轻量级" :rules="[{ required: true, message: '请选择是否支持轻量级', trigger: 'change' }]" prop="isLightWeight">
            <checkbox-plus type="radio" :options="isLightWeightOps" :value.sync="form.isLightWeight" @onChange="isLightWeightChange"/>
          </el-form-item>
          <div v-if="lightWeightConfigsVisible">
            <div class="light-weight-configs" v-for="(item, index) in form.lightWeightConfigs" :key="index">
              <el-form-item label="第三方平台名称">
                <span v-text="item.name"></span>
              </el-form-item>
              <el-form-item label="是否启用" :rules="[{ required: true, message: '请选择是否启用', trigger: 'change' }]" :prop="'lightWeightConfigs.' + index + '.isUse'">
                <checkbox-plus type="radio" :options="isUseOps" :value.sync="item.isUse" />
              </el-form-item>
              <div v-if="item.isUse === '1'">
                <el-form-item label="产品类型" :rules="[{ required: true, message: '请选择产品类型', trigger: 'change' }]" :prop="'lightWeightConfigs.' + index + '.productTypes'">
                  <v-select v-model="item.externalProductType" :options="item.productTypes" :width="width" />
                </el-form-item>
                <el-form-item :label="item.externalProductType === '4' ? 'URL' : 'APPID'" :rules="[{ required: true, message: '请输入完整', trigger: 'change' }]" :prop="'lightWeightConfigs.' + index + '.appId'">
                  <v-input v-model="item.appId" placeholder="请输入" :width="width" />
                </el-form-item>
                <el-form-item v-if="item.externalProductType === '1'" label="PATH" :prop="'lightWeightConfigs.' + index + '.path'">
                  <v-input v-model="item.path" placeholder="请输入" :width="width" :maxlength="9999"/>
                </el-form-item>
                <el-form-item v-if="item.externalProductType === '1'" label="原始ID" :prop="'lightWeightConfigs.' + index + '.extraObject'">
                  <v-input v-model="item.extraObject" placeholder="请输入" :width="width"/>
                </el-form-item>
                
              </div>
            </div>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getExternalProductTypeURL, lightWeightConfigURL, queryLightWeightURL } from './api'
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'

export default {
  name: 'LightWeightConfig',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus
  },
  data () {
    return {
      width: 182,
      lightWeightConfigsVisible: false,
      isLightWeightOps: [{
        label: '是',
        value: '1'
      }, {
        label: '否',
        value: '0'
      }],
      isUseOps: [{
        label: '是',
        value: '1'
      }, {
        label: '否',
        value: '0'
      }],
      form: {
        subId: undefined,
        isLightWeight: '0',
        lightWeightConfigs: []
      },
      submitConfig: {
        queryUrl: queryLightWeightURL,
        submitUrl: lightWeightConfigURL
      }
    }
  },
  created () {
    this.getExternalProductType()
  },
  methods: {
    setSubId (subId) {
      this.form.subId = subId
    },
    isLightWeightChange () {
      const value = this.form.isLightWeight
      this.lightWeightConfigsVisible = value == 1 // eslint-disable-line
    },
    async getExternalProductType () {
      const { status, data } = await this.$axios.get(getExternalProductTypeURL)
      if (status === 100) {
        const lightWeightConfigs = []
        if (data && data.length) {
          data.forEach(item => {
            item['isUse'] = '0'
            item['appId'] = ''
            item['path'] = ''
            item['extraObject'] = ''
            if (item.productTypes && item.productTypes.length) {
              const productTypes = []
              item.productTypes.forEach(productType => {
                productTypes.push({
                  text: productType.name,
                  value: productType.id
                })
              })
              item.productTypes = productTypes
              item['externalProductType'] = item.productTypes[0].value
            }
            lightWeightConfigs.push(item)
          })
        }
        this.form.lightWeightConfigs = lightWeightConfigs
        const subId = this.form.subId
        if (subId) {
          this.$refs.formPanel.getData({ subId })
        }
      }
    },
    update (data) {
      this.form.isLightWeight = String(data.isLightWeight)
      this.isLightWeightChange(this.form.isLightWeight)
      if (data.lightWeightConfigs && data.lightWeightConfigs.length) {
        data.lightWeightConfigs.forEach(item => {
          const index = this.form.lightWeightConfigs.findIndex(_item => {
            return _item.id === item.externalType
          })
          this.form.lightWeightConfigs[index].isUse = '1'
          this.form.lightWeightConfigs[index].appId = item.appId
          this.form.lightWeightConfigs[index].path = item.path
          this.form.lightWeightConfigs[index].extraObject = item.extraObject
          this.form.lightWeightConfigs[index].externalProductType = item.externalProductType
        })
      }
    },
    submitBefore (data) {
      if (!this.form.subId) {
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '基础配置'
        })
        this.$message.info('请先添加基础信息')
        return false
      }
      const lightWeightConfigs = []
      if (data['isLightWeight'] === '1') {
        data['lightWeightConfigs'].forEach(item => {
          if (item.isUse === '1') {
            lightWeightConfigs.push({
              appid: item.appId,
              path: item.path,
              extraObject: item.extraObject,
              externalProductType: item.externalProductType,
              externalType: item.id
            })
          }
        })
      }
      data['lightWeightConfigs'] = lightWeightConfigs
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.LightWeightConfig-wrapper {
  .light-weight-configs {
    border-top: 1px solid #d7dcec;
    padding: 20px 0 0 0;
  }
}
</style>
