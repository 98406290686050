<template>
  <div class="ApplicationLibraryForm-wrapper">
    <tabs-panel :tabRouter="false" :tabs="tabs" :activeLabel.sync="activeLabel" />
  </div>
</template>

<script>
import { TabsPanel } from 'components/bussiness'
import BaseConfig from './BaseConfig'
import LightWeightConfig from './LightWeightConfig'

export default {
  name: 'ApplicationLibraryForm',
  components: {
    TabsPanel
  },
  props: {
    type: String,
    appURL: String
  },
  data () {
    return {
      activeLabel: '基础配置',
      tabs: []
    }
  },
  created () {
    const tabs = [
      {
        label: '基础配置',
        component: BaseConfig,
        props: {
          type: this.type,
          appURL: this.appURL
        }
      }
    ]
    if (this.type === 'platform') {
      tabs.push({
        label: '轻量级配置',
        component: LightWeightConfig
      })
    }
    this.tabs = tabs
  },
  methods: {
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationLibraryForm-wrapper {
  height: 100%;
}
</style>
