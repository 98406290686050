// 新增
const createURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonAdd`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonUpdate`
// 查询详情
const queryURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/subjectCommonView`
// 选择对象
const selectObjecURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/selectObjectSelectTo`

// 产品类型
const getExternalProductTypeURL = `${API_CONFIG.shopBaseURL}subjectLightWeightConfig/subjectExternalConfigList`
// 轻量级应用
const lightWeightConfigURL = `${API_CONFIG.controlBaseURL}subjectLightWeightConfig/subjectLightWeightConfigUpdate`
// 查询详情 - 轻量级应用
const queryLightWeightURL = `${API_CONFIG.shopBaseURL}subjectLightWeightConfig/subjectLightWeightConfigView`

// 工作台用 根据公司id获取 工单树 接口
const getTreeByRegionIdURL = `${API_CONFIG.taskDispatchURL}dispatch/category/getTreeByRegion`

export {
  createURL,
  editURL,
  queryURL,
  selectObjecURL,
  getExternalProductTypeURL,
  lightWeightConfigURL,
  queryLightWeightURL,
  getTreeByRegionIdURL,
}
