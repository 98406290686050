var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "LightWeightConfig-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "应用信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否支持轻量级",
                        rules: [
                          {
                            required: true,
                            message: "请选择是否支持轻量级",
                            trigger: "change",
                          },
                        ],
                        prop: "isLightWeight",
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "radio",
                          options: _vm.isLightWeightOps,
                          value: _vm.form.isLightWeight,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "isLightWeight", $event)
                          },
                          onChange: _vm.isLightWeightChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.lightWeightConfigsVisible
                    ? _c(
                        "div",
                        _vm._l(
                          _vm.form.lightWeightConfigs,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "light-weight-configs",
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "第三方平台名称" } },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(item.name),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否启用",
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择是否启用",
                                          trigger: "change",
                                        },
                                      ],
                                      prop:
                                        "lightWeightConfigs." +
                                        index +
                                        ".isUse",
                                    },
                                  },
                                  [
                                    _c("checkbox-plus", {
                                      attrs: {
                                        type: "radio",
                                        options: _vm.isUseOps,
                                        value: item.isUse,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(item, "isUse", $event)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                item.isUse === "1"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "产品类型",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请选择产品类型",
                                                  trigger: "change",
                                                },
                                              ],
                                              prop:
                                                "lightWeightConfigs." +
                                                index +
                                                ".productTypes",
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                options: item.productTypes,
                                                width: _vm.width,
                                              },
                                              model: {
                                                value: item.externalProductType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "externalProductType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.externalProductType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                item.externalProductType === "4"
                                                  ? "URL"
                                                  : "APPID",
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请输入完整",
                                                  trigger: "change",
                                                },
                                              ],
                                              prop:
                                                "lightWeightConfigs." +
                                                index +
                                                ".appId",
                                            },
                                          },
                                          [
                                            _c("v-input", {
                                              attrs: {
                                                placeholder: "请输入",
                                                width: _vm.width,
                                              },
                                              model: {
                                                value: item.appId,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "appId", $$v)
                                                },
                                                expression: "item.appId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        item.externalProductType === "1"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "PATH",
                                                  prop:
                                                    "lightWeightConfigs." +
                                                    index +
                                                    ".path",
                                                },
                                              },
                                              [
                                                _c("v-input", {
                                                  attrs: {
                                                    placeholder: "请输入",
                                                    width: _vm.width,
                                                    maxlength: 9999,
                                                  },
                                                  model: {
                                                    value: item.path,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "path",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.path",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        item.externalProductType === "1"
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "原始ID",
                                                  prop:
                                                    "lightWeightConfigs." +
                                                    index +
                                                    ".extraObject",
                                                },
                                              },
                                              [
                                                _c("v-input", {
                                                  attrs: {
                                                    placeholder: "请输入",
                                                    width: _vm.width,
                                                  },
                                                  model: {
                                                    value: item.extraObject,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "extraObject",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.extraObject",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }