<template>
  <div class="BaseConfig-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccess"
                @update="update">
      <col2-detail>
        <col2-block title="应用信息">
          <el-form-item v-if="subCodeVisible"
                        label="应用编码">
            <span>{{ subCode }}</span>
          </el-form-item>
          <div v-if="regionVisible">
            <el-form-item v-if="regionName"
                          label="所属公司">
              <span v-text="regionName"></span>
            </el-form-item>
            <el-form-item v-else
                          label="所属公司"
                          prop="regionId"
                          :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
              <v-select2 ref="regionNameRef" v-model="form.regionId"
                         placeholder="输入公司名称"
                         v-bind="regionParams"
                         :width="width"
                         @onChange="regionChange" />
            </el-form-item>
          </div>
          <div v-if="communityVisible">
            <el-form-item v-if="communityName"
                          label="所属项目">
              <span v-text="communityName"></span>
            </el-form-item>
            <el-form-item v-else
                          label="所属项目"
                          prop="communityId"
                          :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]">
              <v-select2 v-model="form.communityId"
                         placeholder="输入项目名称"
                         v-bind="communityParams"
                         :subjoin="communitySubjoin"
                         :width="width"
                         @onChange="communityChange" />
            </el-form-item>
          </div>
          <el-form-item label="应用名称"
                        :rules="[{ required: true, message: '请输入应用名称', trigger: 'blur' },
          { validator: validateAppName, trigger: 'blur' }]"
                        prop="subName">
            <v-input v-model="form.subName"
                     placeholder="请填写最多5个汉字或10个英文/数字"
                     :maxlength="10"
                     :width="300"
                     @change="subNameChange" />
          </el-form-item>
          <el-form-item label="应用图标"
                        :rules="[{ required: true, message: '请上传应用图标', trigger: 'change' }]"
                        prop="subImg">
            <v-uploader :action="uploadURL"
                        :imgUrls.sync="form.subImg">
              <template #tip>
                <span>注：图片要求：宽132px高132px，图片小于50K</span>
              </template>
            </v-uploader>
          </el-form-item>

          <!-- 应用形式 -->
          <el-form-item v-if="regionVisible" label="应用形式" :rules="[{ required: true, message: '请选择应用形式',trigger: 'change'}]" prop="applicationForm">
            <v-select
              v-model="form.applicationForm"
              :options="appTypeMap"
              :width="width"
              @change="appTypeChange"
            />
          </el-form-item>

          <!-- 工单分类 -->
          <el-form-item v-if="form.applicationForm === 1" label="工单分类" prop="orderTypeTopId">
            <v-select
              v-model="form.orderTypeTopId"
              :options="orderTypeOptions"
              :width="width"
            />
          </el-form-item>

          <!-- 应急分类 -->
          <el-form-item label="所属类别"
                        v-if="form.applicationForm !== 1"
                        :rules="[{ required: true, message: '请选择所属类别', trigger: 'change' }]"
                        prop="subHandle">
            <v-select v-model="form.subHandle"
                      :options="subHandleOps"
                      :disabled="subHandleDisabled"
                      :width="width"
                      @change="subHandleChange" />
          </el-form-item>
          <el-form-item v-if="manageOauthVisible && form.applicationForm !== 1"
                        label="物管授权"
                        :rules="[{ required: true, message: '请选择物管授权', trigger: 'change' }]"
                        prop="manageOauth">
            <v-select v-model="form.manageOauth"
                      :options="manageOauthOps"
                      :width="width" />
          </el-form-item>
          <el-form-item v-if="sectionVisible"
                        label="所属版块"
                        :rules="[{ type: 'array', required: true, message: '请选择所属版块', trigger: 'change' }]"
                        prop="section">
            <v-cascader :value.sync="form.section"
                        :options="sectionOps"
                        :props="props"
                        @change="sectionChange" />
          </el-form-item>
          <el-form-item v-if="subTopicTypeVisible"
                        label="话题类型"
                        :rules="[{ required: true, message: '请选择话题类型', trigger: 'change' }]"
                        prop="subTopicType">
            <v-select v-model="form.subTopicType"
                      :options="subTopicTypeOps"
                      :width="width" />
          </el-form-item>
          <el-form-item label="应用类型"
                        v-if="form.applicationForm !== 1"
                        :rules="[{ required: true, message: '请选择应用类型', trigger: 'change' }]"
                        prop="subType">
            <v-select v-model="form.subType"
                      :options="subTypeOps"
                      :disabled="subTypeDisabled || customAddApp"
                      :width="width"
                      @change="subTypeChange" />
          </el-form-item>
          <el-form-item v-if="isInternalVisible && form.applicationForm !== 1"
                        label="应用归属"
                        :rules="[{ required: true, message: '请选择应用归属', trigger: 'change' }]"
                        prop="isInternal">
            <v-select v-model="form.isInternal"
                      :options="isInternalOps"
                      :disabled="customAddApp"
                      :width="width" />
          </el-form-item>
          <div v-if="subRelationVisible">
            <el-form-item :label="subRelationLabel"
                          :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
                          prop="subRelationId">
              <v-select2 style="display: inline-block;"
                         v-model="form.subRelationId"
                         placeholder="请选择"
                         v-bind="subRelationParams"
                         :subjoin="subRelationSubjoin"
                         :width="width" />
              <label class="tips"
                     v-text="subRelationTips"></label>
            </el-form-item>
          </div>
          <el-form-item v-if="usedOauthVisible && form.applicationForm !== 1"
                        label="授权状态"
                        :rules="[{ required: true, message: '请选择授权状态', trigger: 'change' }]"
                        prop="usedOauth">
            <v-select v-model="form.usedOauth"
                      :options="usedOauthOps"
                      :disabled="customAddApp"
                      :width="width" />
          </el-form-item>
          <el-form-item v-if="subUrlVisible && form.applicationForm !== 1"
                        label="页面URL"
                        :rules="[{ required: true, message: '请输入页面URL', trigger: 'blur' }]"
                        prop="subUrl">
            <v-input v-model="form.subUrl"
                     placeholder="请输入页面URL"
                     :disabled="customAddApp"
                     :maxlength="300"
                     :width="600" />
          </el-form-item>
          <el-form-item v-if="showUserProtocol"
                        label="用户协议和隐私政策"
                        :rules="[{ required: true, message: '请输入用户协议和隐私政策', trigger: 'blur' }]"
                        prop="userProtocol">
            <v-ueditor :maxLength="20000"
                       v-model="form.userProtocol" />
          </el-form-item>
          <el-form-item v-if="subStatusVisible"
                        label="应用状态"
                        :rules="[{ required: true, message: '请选择应用状态', trigger: 'change' }]"
                        prop="subStatus">
            <v-select v-model="form.subStatus"
                      :options="subStatusOps"
                      :width="width" />
          </el-form-item>
          <el-form-item label="应用描述"
                        :rules="[{ required: true, message: '请输入应用描述', trigger: 'blur' }]"
                        prop="subNote">
            <v-input v-model="form.subNote"
                     placeholder="请输入应用描述"
                     :maxlength="10"
                     :width="width" />
            <label class="tips">注：请简单描述应用内容</label>
          </el-form-item>
        </col2-block>
        <col2-block v-if="oauthInfoVisible"
                    title="授权信息">
          <el-form-item label="第三方应用名称"
                        :rules="[{ required: true, message: '请输入第三方应用名称', trigger: 'blur' }]"
                        prop="oauthName">
            <v-input v-model="form.oauthName"
                     placeholder="请输入第三方应用名称"
                     :maxlength="10"
                     :width="width" />
          </el-form-item>
          <el-form-item label="是否高级授权"
                        :rules="[{ required: true, message: '请选择是否高级授权', trigger: 'change' }]"
                        prop="oauthHighInterface">
            <v-select v-model="form.oauthHighInterface"
                      :options="oauthHighInterfaceOps"
                      :width="width" />
            <label class="tips">注：允许高级授权，第三方平台可通过接口获得用户全部个人资料</label>
          </el-form-item>
          <el-form-item label="回调URL"
                        :rules="[{ required: true, message: '请输入回调URL', trigger: 'blur' }]"
                        prop="oauthDomainUrl">
            <v-input v-model="form.oauthDomainUrl"
                     placeholder="请输入回调URL"
                     :maxlength="200"
                     :width="width" />
          </el-form-item>
          <el-form-item label="授权有效时间"
                        :rules="[{ required: true, message: '请输入授权有效时间', trigger: 'blur' }]"
                        prop="oauthLoginTimeout">
            <v-input-number v-model="form.oauthLoginTimeout"
                            placeholder="请输入授权有效时间"
                            :width="width" />
          </el-form-item>
          <el-form-item label="第三方密钥">
            <v-input v-model="form.oauthClientSecret"
                     disabled
                     :width="width" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { regionParams, communityParams } from 'common/select2Params'
import { uploadURL } from 'common/api'
import { createURL, editURL, queryURL, selectObjecURL, getTreeByRegionIdURL } from './api'
import { setSubHandleOps, setManageOauthOps, subTypeMap, setSubTypeOps, setUsedOauthOps, setSubStatusOps, setOauthHighInterfaceOps, setSubTopicTypeOps, setIsInternalOps, appTypeHandleOps } from '../map'
import { vUploader, vCascader, vUeditor } from 'components/control'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { getSectionOps } from '../common'

export default {
  name: 'BaseConfig',
  components: {
    vUploader,
    vCascader,
    Col2Detail,
    Col2Block,
    vUeditor
  },
  props: {
    type: String,
    appURL: String
  },
  data () {
    return {
      width: 200,
      uploadURL: `${uploadURL}?module=icon`,
      subHandleOps: setSubHandleOps(),
      appTypeMap: appTypeHandleOps(),
      manageOauthOps: setManageOauthOps(),
      sectionOps: [],
      props: {
        children: 'options'
      },
      subTopicTypeVisible: false,
      subTopicTypeOps: setSubTopicTypeOps(),
      subTypeOps: [],
      isInternalOps: setIsInternalOps(),
      subTypeBusinessOps: [],
      subTypeProjectOps: [],
      usedOauthOps: setUsedOauthOps(),
      subStatusOps: setSubStatusOps(),
      subCodeVisible: false,
      subCode: '',
      manageOauthVisible: false,
      usedOauthVisible: false,
      subUrlVisible: false,
      subStatusVisible: true,
      oauthHighInterfaceOps: setOauthHighInterfaceOps(),
      subRelationParams: {
        searchUrl: selectObjecURL
      },
      subHandleDisabled: false,
      subTypeDisabled: false,
      regionParams,
      communityParams,
      regionName: '',
      communityName: '',
      sectionIsHuaTi: false,
      form: {
        regionId: undefined,
        communityId: undefined,
        subId: undefined,
        subName: '',
        subImg: '',
        applicationForm: 0,      // 应用形式,0:普通应用，1：工单应用
        orderTypeTopId: undefined,      // 工单分类id
        subHandle: undefined,
        manageOauth: undefined,
        section: undefined,
        subTopicType: undefined,
        subType: undefined,
        isInternal: 0,
        subRelationId: '',
        usedOauth: undefined,
        subUrl: '',
        subStatus: undefined,
        subNote: '',
        oauthName: '',
        oauthHighInterface: undefined,
        oauthDomainUrl: '',
        oauthLoginTimeout: 30,
        oauthClientSecret: '',
        userProtocol: ''
      },
      submitConfig: {
        queryUrl: queryURL,
        submitMethod: '',
        submitUrl: ''
      },
      orderTypeOptions: [{ value: undefined,text: '请选择' }],     // 工单分类
    }
  },
  async mounted () {
    this.subTypeBusinessOps = setSubTypeOps().splice(1, 4)
    this.subTypeProjectOps = setSubTypeOps().splice(1, 1)
    const { subId } = this.$route.query
    let title = '新增'
    if (subId !== undefined) {
      title = '编辑'
      // 显示应用编码
      this.submitConfig.submitMethod = 'PUT'
      this.submitConfig.submitUrl = editURL
      if (this.type === 'platform') {
        this.subCodeVisible = true
      }
      this.$refs.formPanel.getData({ subId })
    } else {
      this.submitConfig.submitMethod = 'POST'
      this.submitConfig.submitUrl = createURL
      let userType = this.$store.state.userInfo.userType
      // 所属版块（平台应用没有所属版块）
      if ((this.type === 'tenant' && userType === '102') || (this.type === 'project' && userType === '101')) {
        this.sectionOps = await getSectionOps({}, true)
      }
      // 设置下拉选择框的默认选项
      this.form.subHandle = this.subHandleOps[0].value
      this.form.manageOauth = this.manageOauthOps[0].value
      this.form.usedOauth = this.usedOauthOps[0].value
      this.form.subStatus = this.subStatusOps[0].value
      this.form.oauthHighInterface = this.oauthHighInterfaceOps[0].value
      this.form.subTopicType = this.subTopicTypeOps[0].value

      if (this.customAddApp) {
        title = '创建应用'
        // 代表是从自定义表单进来创建应用的,默认为内部应用，无需授权，类型为h5，页面url自动填入表单的填写页链接，创建时禁止修改
        this.form.isInternal = 1
        this.form.usedOauth = 0
        this.form.subUrl = this.appURL
        this.form.subType = 7
      }
      this.$setBreadcrumb(title)
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    permissionAttribute () {
      // 从自定义列表跳转过来，  this.$store.getters.getPermission('attribute') 这样读取到的权限是 false
      // 需要下面的写法，才能读取出正确的权限；注意【applicationLibraryPlatform】这个是这个模块的url
      let permission = this.$store.state.menuStore.menuPermission['applicationLibraryPlatform']
      if(permission === undefined) {
        return false
      }
      return permission.attribute === 1
    },
    isInternalVisible () {
      // RONG-3286 按照阿宪要求，去掉 系统账号 判断的逻辑
      return this.permissionAttribute && this.form.subType === 7
    },
    showUserProtocol () {
      return ((!this.isInternalVisible && this.form.subType === 7) || this.isInternalVisible) && this.form.isInternal === 0
    },
    subRelationVisible () {
      return [2, 11, 13].includes(this.form.subType) || (this.form.subType === 7 && this.form.subHandle === 1)
    },
    subRelationLabel () {
      return [2, 11, 13].includes(this.form.subType) ? '选择对象' : '选择关联门店'
    },
    subRelationTips () {
      return [2, 11, 13].includes(this.form.subType) ? '注：请选择有合作关系的商家门店/门店商品' : '注：请选择有合作关系的商家门店'
    },
    oauthInfoVisible () {
      return this.form.subType === 7 && this.form.usedOauth === 1
    },
    sectionVisible () {
      return this.type !== 'platform'
    },
    regionVisible () {
      let visible = false
      let userType = this.$store.state.userInfo.userType
      if (this.type === 'tenant' && ['106', '102', '100'].includes(userType)) {
        visible = true
      } else if (this.type === 'project' && ['106', '102', '100'].includes(userType)) {
        visible = true
      }
      return visible
    },
    communityVisible () {
      let visible = false
      let userType = this.$store.state.userInfo.userType
      if (this.type === 'project' && ['106', '100', '102'].includes(userType)) {
        visible = true
      }
      return visible
    },
    communitySubjoin () {
      return {
        regionId: this.form.regionId,
        isNormal: 1
      }
    },

    subRelationSubjoin () {
      return {
        subType: this.form.subType
      }
    },
    // 代表是从自定义表单进来创建应用的
    customAddApp () {
      return this.appURL && this.appURL.length > 0
    }
  },
  methods: {

    // APP2-1306 应用名称支持10位字符APP2-1332
    validateAppName (rule, value, callback) {
      if (value && value.length > 0) {
        let regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
        if (!regExp.test(value)) {
          callback(new Error('请填写最多5个汉字或10个英文/数字'))
        } else {
          let len = this.getByteLen(value)
          if (len > 10) {
            callback(new Error('请填写最多5个汉字或10个英文/数字'))
          } else {
            callback()
          }
        }
      } else {
        callback(new Error('请输入应用名称'))
      }
    },

    getByteLen (val) {
      var len = 0
      for (var i = 0; i < val.length; i++) {
        var a = val.charAt(i)
        // eslint-disable-next-line no-control-regex
        if (a.match(/^[\u4e00-\u9fa5]/ig) != null) {
          len += 2
        } else {
          len += 1
        }
      }
      return len
    },

    async update (data) {
      if(this.regionVisible) {
        this.getTreeByRegionIdURL(data.regionId)
      }
      this.subCode = data.subCode
      if (data.regionName) {
        this.regionName = data.regionName
      }
      if (data.communityName) {
        this.communityName = data.communityName
      }
      const subType = data.subType
      if (subType === 8) {
        const subTypeText = subTypeMap[subType]
        const op = {
          text: subTypeText,
          value: subType
        }
        this.subTypeBusinessOps.unshift(op)
        this.subTypeProjectOps.unshift(op)
        this.subHandleDisabled = true
        this.subTypeDisabled = true
      }
      this.$emit('contact', {
        index: 1,
        method: 'setSubId',
        data: data.subId
      })
      Object.keys(this.form).forEach(key => {
        if (['subType', 'subRelationId'].includes(key)) {
          this.$nextTick(() => {
            this.form[key] = data[key]
          }, 20)
        } else {
          this.form[key] = data[key]
        }
      })
      const { communityId, regionId } = this.$route.query
      this.sectionOps = await getSectionOps({
        params: {
          communityId,
          regionId
        }
      }, true)
      // 所属版块
      const firstLevel = data['parentSectionId']
      const secondLevel = data['sectionId']
      if (firstLevel && secondLevel) {
        let firstLevelIndex = this.sectionOps.findIndex((sectionOp) => {
          return sectionOp.value === firstLevel
        })
        if (firstLevelIndex === -1) {
          this.sectionOps.unshift({
            label: data['parentSectionName'],
            value: firstLevel,
            options: []
          })
          firstLevelIndex = 0
        }
        let secondLevelIndex = this.sectionOps[firstLevelIndex].options.findIndex((sectionOp) => {
          return sectionOp.value === secondLevel
        })
        if (secondLevelIndex === -1) {
          this.sectionOps[firstLevelIndex].options.unshift({
            label: data['sectionName'],
            value: secondLevel
          })
        }
        this.$nextTick(() => {
          this.form['section'] = [firstLevel, secondLevel]
        }, 20)
        if(data.orderTypeTopId === '') {
          this.form.orderTypeTopId = undefined
        }
      }
    },
    submitBefore (data) {
      if (!this.isInternalVisible) {
        data['isInternal'] = undefined
      }
      if (this.sectionVisible) {
        const section = data['section']
        if (section && section.length) {
          data['sectionId'] = section[1]
        }
        delete data['section']
        if (!this.subTopicTypeVisible) {
          data['subTopicType'] = undefined
        }
      }
      if (data['subHandle'] === 1) {
        data['manageOauth'] = 0
      }
      if (this.type === 'project') {
        if (data['subHandle'] === 0) {
          data['subStatus'] = undefined
        }
      }

      // 操作日志的数据对象参数
      let regionName_
      if (this.regionVisible && this.regionName) {
        regionName_ = this.regionName
      } else {
        if (this.$refs.regionNameRef && this.$refs.regionNameRef.getBackTextValue()) {
          regionName_ = this.$refs.regionNameRef.getBackTextValue()
        }
      }

      let dataObject = `${data.subName}`
      if (regionName_) {
        dataObject += `-${regionName_}`
      }
      data.dataObject = dataObject

      // 工单分类
      if(data.applicationForm === 1) {
        let url = '';
        //得根据域名来判断了
        if(window.origin === 'https://yshk8s.wisharetec.com' || window.origin === 'https://shushuapitest.4001113900.com:10020') {
            url = `https://saasdev.wisharetec.com/h5Manage-order/#/userOrder/report`
        }else {
          url = ` https://saas.wisharetec.com/h5Manage-order/#/userOrder/report`

        }
        if(data.orderTypeTopId !== undefined) {
          url = `${url}?firstCategoryId=${data.orderTypeTopId}&orderTitle=${encodeURIComponent(data.subName)}`
        }
        data.subUrl = url
      }
      if(data.orderTypeTopId === undefined) {
        data.orderTypeTopId = ''
      }

      return true
    },
    submitSuccess (data, msg) {
      if (!this.form.subId) {
        this.$emit('contact', {
          index: 1,
          method: 'setSubId',
          data
        })
      }
      if (this.type === 'platform') {
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '轻量级配置'
        })
      } else {
        this.$router.go(-1)
      }
      this.$message.success('保存成功')
    },
    subNameChange (value) {
      // console.log(value)
    },
    async regionChange (option) {
      if (this.type === 'tenant') {
        this.form.section = undefined
        if (option) {
          const regionId = option && option.id
          this.sectionOps = await getSectionOps({
            params: {
              regionId
            }
          }, true)
        } else {
          this.sectionOps = []
        }
      }
      // 处理工单分类相关
      this.orderTypeOptions = [{ value: undefined,text: '请选择' }]
      this.form.orderTypeTopId = undefined
      this.getTreeByRegionIdURL(option.id)
    },
    async communityChange (option) {
      if (this.type === 'project') {
        this.form.section = undefined
        if (option) {
          const communityId = option && option.id
          this.sectionOps = await getSectionOps({
            params: {
              communityId
            }
          }, true)
        } else {
          this.sectionOps = []
        }
      }
    },
    subHandleChange (value) {
      const section = this.form.section
      // 如果所属版块是业务联动类型（目前只有一种：互动交流-话题）
      if (this.sectionIsHuaTi) {
        this.subTopicTypeVisible = true
        this.subTypeOps = setSubTypeOps().splice(0, 1)
      } else {
        if (value === 0) {
          this.subTypeOps = this.subTypeProjectOps
        } else if (value === 1) {
          this.subTypeOps = this.subTypeBusinessOps
        }
      }
      const val = this.subTypeOps[0].value
      this.form.subType = val
      // 改变subTypeOps之后马上改变subType时不会触发回调方法，手动触发一波
      this.subTypeChange(val)
      // 物管授权 & 应用状态
      if (value === 0) {
        this.manageOauthVisible = true
        if (this.type === 'project') {
          this.subStatusVisible = false
        }
      } else if (value === 1) {
        this.manageOauthVisible = false
        if (this.type === 'project') {
          this.subStatusVisible = true
        }
      }
    },
    appTypeChange (value) {
      if(value === 1) {
        this.form.subHandle = 0
        this.form.isInternal = 1
        this.form.usedOauth = 0
        this.form.manageOauth = 0
        this.subHandleChange(0)
      }
    },
    subTypeChange (value) {
      if (value === 7) {
        this.usedOauthVisible = true
        this.subUrlVisible = true
      } else {
        this.usedOauthVisible = false
        this.subUrlVisible = false
      }
    },
    sectionChange (section) {
      if (section && section.length && section[1].sectionType === 1) {
        this.subTopicTypeVisible = true
        this.sectionIsHuaTi = true
        this.subTypeOps = setSubTypeOps().splice(0, 1)
        const val = this.subTypeOps[0].value
        this.form.subType = val
        // 改变subTypeOps之后马上改变subType时不会触发回调方法，手动触发一波
        this.subTypeChange(val)
      } else {
        this.subTopicTypeVisible = false
        // 如果所属版块是业务联动类型（目前只有一种：互动交流-话题）切换过来的，才需要重新应用类型subTypeOps
        if (this.sectionIsHuaTi) {
          this.sectionIsHuaTi = false
          this.subHandleChange(this.form.subHandle)
        }
      }
    },
    // 获取新工单分类
    getTreeByRegionIdURL(regionId) {
      this.$axios.get(getTreeByRegionIdURL, {
        params: {
          regionId
        }
      }).then(res => {
        if (res.status === 100 && res.data.length !== 0) {
          res.data.forEach(item => {
              this.orderTypeOptions.push({value: item.id, text: item.name})
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.BaseConfig-wrapper {
  .tips {
    margin-left: 20px;
  }
}
</style>
