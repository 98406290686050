var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BaseConfig-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "应用信息" } },
                [
                  _vm.subCodeVisible
                    ? _c("el-form-item", { attrs: { label: "应用编码" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.subCode))]),
                      ])
                    : _vm._e(),
                  _vm.regionVisible
                    ? _c(
                        "div",
                        [
                          _vm.regionName
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "所属公司" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.regionName),
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属公司",
                                    prop: "regionId",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择所属公司",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        ref: "regionNameRef",
                                        attrs: {
                                          placeholder: "输入公司名称",
                                          width: _vm.width,
                                        },
                                        on: { onChange: _vm.regionChange },
                                        model: {
                                          value: _vm.form.regionId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "regionId", $$v)
                                          },
                                          expression: "form.regionId",
                                        },
                                      },
                                      "v-select2",
                                      _vm.regionParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.communityVisible
                    ? _c(
                        "div",
                        [
                          _vm.communityName
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "所属项目" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.communityName),
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属项目",
                                    prop: "communityId",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择所属项目",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder: "输入项目名称",
                                          subjoin: _vm.communitySubjoin,
                                          width: _vm.width,
                                        },
                                        on: { onChange: _vm.communityChange },
                                        model: {
                                          value: _vm.form.communityId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "communityId",
                                              $$v
                                            )
                                          },
                                          expression: "form.communityId",
                                        },
                                      },
                                      "v-select2",
                                      _vm.communityParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入应用名称",
                            trigger: "blur",
                          },
                          { validator: _vm.validateAppName, trigger: "blur" },
                        ],
                        prop: "subName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请填写最多5个汉字或10个英文/数字",
                          maxlength: 10,
                          width: 300,
                        },
                        on: { change: _vm.subNameChange },
                        model: {
                          value: _vm.form.subName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subName", $$v)
                          },
                          expression: "form.subName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用图标",
                        rules: [
                          {
                            required: true,
                            message: "请上传应用图标",
                            trigger: "change",
                          },
                        ],
                        prop: "subImg",
                      },
                    },
                    [
                      _c("v-uploader", {
                        attrs: {
                          action: _vm.uploadURL,
                          imgUrls: _vm.form.subImg,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "subImg", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "subImg", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "注：图片要求：宽132px高132px，图片小于50K"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm.regionVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用形式",
                            rules: [
                              {
                                required: true,
                                message: "请选择应用形式",
                                trigger: "change",
                              },
                            ],
                            prop: "applicationForm",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.appTypeMap,
                              width: _vm.width,
                            },
                            on: { change: _vm.appTypeChange },
                            model: {
                              value: _vm.form.applicationForm,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "applicationForm", $$v)
                              },
                              expression: "form.applicationForm",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.applicationForm === 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "工单分类", prop: "orderTypeTopId" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.orderTypeOptions,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.orderTypeTopId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderTypeTopId", $$v)
                              },
                              expression: "form.orderTypeTopId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属类别",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属类别",
                                trigger: "change",
                              },
                            ],
                            prop: "subHandle",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.subHandleOps,
                              disabled: _vm.subHandleDisabled,
                              width: _vm.width,
                            },
                            on: { change: _vm.subHandleChange },
                            model: {
                              value: _vm.form.subHandle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subHandle", $$v)
                              },
                              expression: "form.subHandle",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.manageOauthVisible && _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物管授权",
                            rules: [
                              {
                                required: true,
                                message: "请选择物管授权",
                                trigger: "change",
                              },
                            ],
                            prop: "manageOauth",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.manageOauthOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.manageOauth,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "manageOauth", $$v)
                              },
                              expression: "form.manageOauth",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.sectionVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属版块",
                            rules: [
                              {
                                type: "array",
                                required: true,
                                message: "请选择所属版块",
                                trigger: "change",
                              },
                            ],
                            prop: "section",
                          },
                        },
                        [
                          _c("v-cascader", {
                            attrs: {
                              value: _vm.form.section,
                              options: _vm.sectionOps,
                              props: _vm.props,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "section", $event)
                              },
                              change: _vm.sectionChange,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.subTopicTypeVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "话题类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择话题类型",
                                trigger: "change",
                              },
                            ],
                            prop: "subTopicType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.subTopicTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.subTopicType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subTopicType", $$v)
                              },
                              expression: "form.subTopicType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择应用类型",
                                trigger: "change",
                              },
                            ],
                            prop: "subType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.subTypeOps,
                              disabled: _vm.subTypeDisabled || _vm.customAddApp,
                              width: _vm.width,
                            },
                            on: { change: _vm.subTypeChange },
                            model: {
                              value: _vm.form.subType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subType", $$v)
                              },
                              expression: "form.subType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isInternalVisible && _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用归属",
                            rules: [
                              {
                                required: true,
                                message: "请选择应用归属",
                                trigger: "change",
                              },
                            ],
                            prop: "isInternal",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.isInternalOps,
                              disabled: _vm.customAddApp,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.isInternal,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isInternal", $$v)
                              },
                              expression: "form.isInternal",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.subRelationVisible
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.subRelationLabel,
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择",
                                    trigger: "change",
                                  },
                                ],
                                prop: "subRelationId",
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      placeholder: "请选择",
                                      subjoin: _vm.subRelationSubjoin,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.subRelationId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "subRelationId", $$v)
                                      },
                                      expression: "form.subRelationId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.subRelationParams,
                                  false
                                )
                              ),
                              _c("label", {
                                staticClass: "tips",
                                domProps: {
                                  textContent: _vm._s(_vm.subRelationTips),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.usedOauthVisible && _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "授权状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择授权状态",
                                trigger: "change",
                              },
                            ],
                            prop: "usedOauth",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.usedOauthOps,
                              disabled: _vm.customAddApp,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.usedOauth,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "usedOauth", $$v)
                              },
                              expression: "form.usedOauth",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.subUrlVisible && _vm.form.applicationForm !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "页面URL",
                            rules: [
                              {
                                required: true,
                                message: "请输入页面URL",
                                trigger: "blur",
                              },
                            ],
                            prop: "subUrl",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入页面URL",
                              disabled: _vm.customAddApp,
                              maxlength: 300,
                              width: 600,
                            },
                            model: {
                              value: _vm.form.subUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subUrl", $$v)
                              },
                              expression: "form.subUrl",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showUserProtocol
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "用户协议和隐私政策",
                            rules: [
                              {
                                required: true,
                                message: "请输入用户协议和隐私政策",
                                trigger: "blur",
                              },
                            ],
                            prop: "userProtocol",
                          },
                        },
                        [
                          _c("v-ueditor", {
                            attrs: { maxLength: 20000 },
                            model: {
                              value: _vm.form.userProtocol,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userProtocol", $$v)
                              },
                              expression: "form.userProtocol",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.subStatusVisible
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择应用状态",
                                trigger: "change",
                              },
                            ],
                            prop: "subStatus",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.subStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.subStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subStatus", $$v)
                              },
                              expression: "form.subStatus",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用描述",
                        rules: [
                          {
                            required: true,
                            message: "请输入应用描述",
                            trigger: "blur",
                          },
                        ],
                        prop: "subNote",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入应用描述",
                          maxlength: 10,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.subNote,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subNote", $$v)
                          },
                          expression: "form.subNote",
                        },
                      }),
                      _c("label", { staticClass: "tips" }, [
                        _vm._v("注：请简单描述应用内容"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.oauthInfoVisible
                ? _c(
                    "col2-block",
                    { attrs: { title: "授权信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "第三方应用名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入第三方应用名称",
                                trigger: "blur",
                              },
                            ],
                            prop: "oauthName",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入第三方应用名称",
                              maxlength: 10,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.oauthName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oauthName", $$v)
                              },
                              expression: "form.oauthName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否高级授权",
                            rules: [
                              {
                                required: true,
                                message: "请选择是否高级授权",
                                trigger: "change",
                              },
                            ],
                            prop: "oauthHighInterface",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.oauthHighInterfaceOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.oauthHighInterface,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oauthHighInterface", $$v)
                              },
                              expression: "form.oauthHighInterface",
                            },
                          }),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v(
                              "注：允许高级授权，第三方平台可通过接口获得用户全部个人资料"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "回调URL",
                            rules: [
                              {
                                required: true,
                                message: "请输入回调URL",
                                trigger: "blur",
                              },
                            ],
                            prop: "oauthDomainUrl",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入回调URL",
                              maxlength: 200,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.oauthDomainUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oauthDomainUrl", $$v)
                              },
                              expression: "form.oauthDomainUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "授权有效时间",
                            rules: [
                              {
                                required: true,
                                message: "请输入授权有效时间",
                                trigger: "blur",
                              },
                            ],
                            prop: "oauthLoginTimeout",
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              placeholder: "请输入授权有效时间",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.oauthLoginTimeout,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oauthLoginTimeout", $$v)
                              },
                              expression: "form.oauthLoginTimeout",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "第三方密钥" } },
                        [
                          _c("v-input", {
                            attrs: { disabled: "", width: _vm.width },
                            model: {
                              value: _vm.form.oauthClientSecret,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "oauthClientSecret", $$v)
                              },
                              expression: "form.oauthClientSecret",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }